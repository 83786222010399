import React from 'react';
import {Menu} from './components/Menu';
import {Socials} from './components/Socials';

import './stylesheets/home.scss';
import './stylesheets/menu.scss';

import './App.css';


function App() {
  return (
    <div className="App">
      <header className="App-header">
              <div className="section">
                  {/*<Menu/>*/}
                  <div className="oop"></div>
                  <div className="fixed">
                      <h1 className="large">Sushmita Chaudhary</h1>
                      <p>Product Developer</p>
                  </div>
                  <div className="fixed-2"><Socials/></div>
              </div>
              <div className="section">
                  <div className="p fixed">
                      <h1>Coming Soon</h1>
                      <p>For projects, visit <a href="https://github.com/sushmita-c">here</a>.</p>
                  </div>
              </div>
      </header>
    </div>
  );
}

export default App;
