import React from "react";
import '../stylesheets/socials.scss';

export const Socials = () => (
    // <div className="fixed">
        <div className="social-container">
            <ul className="social-icons">
                <li><a href="https://twitter.com/schau29"><i className="fa fa-twitter"></i></a></li>
                <li><a href="https://github.com/sushmita-c"><i className="fa fa-github"></i></a></li>
                <li><a href="https://www.linkedin.com/in/sushmitachaudhary/"><i className="fa fa-linkedin"></i></a></li>
                {/*<li><a href="#"><i className="fa fa-behance"></i></a></li>*/}
                <li><a href="mailto:mail@sushmita.co"><i className="fa fa-comments"></i></a></li>
            </ul>
        </div>
    // </div>
);